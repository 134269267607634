import React from "react";
// import { Link } from "gatsby";
import styled from "styled-components";
import Seo from "../components/seo";
// import { Header, SubHeader, Body } from "../components/Typography";
import { SiteContent, TextImageBlock } from "../components/Layouts";
// import { Colors } from "../theme";
import { ResdidentialData } from "../data/residential";
import "../theme/css/animations.css";
import IndustryHero from "../components/Heros/IndustryHero";

const ResidentialPageWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  min-height: 1200px;
`;

const CommercialPage = () => {

    let { hero, textblocks } = ResdidentialData;

    return (
      <ResidentialPageWrapper>
      <Seo title="VASTech Solutions, Audio Visual Security Technology For Commercial — WNY" />
      <IndustryHero residential data={hero} />

        <SiteContent column>
            {textblocks.map((data, index) => {
              return (
                <TextImageBlock data={data} index={index} key={'block ' + index}/>
              );
            })}
          </SiteContent>
        </ResidentialPageWrapper>
    );
}

export default CommercialPage;
